@import '../../styles/lib/helpers';
@import '../../styles/lib/variables';
@import '../NativeShareButton/nativeShareButton.module.scss';

$default-padding: 2.0625rem;
$header-footer-height: 70px;
$mobile-header-footer-height: 38px;
$mobile-padding: 14px;
$mobile-margin-bottom: 60px;
$mobile-double-padding: $mobile-padding * 2;

.subbuzzShareModalOverlay {
  position: fixed;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 600;
  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  &.closed {
    display: none;
  }
  &.open {
    display: block;
  }
  @media (max-width: $mobile-width) {
    overflow-y: scroll;
    height: 100%;
    max-height: 100%;
  }
  animation-delay: 1s;
  animation-duration: .15s;
  animation-fill-mode: both;
  animation-name: modal__content-fade;
}
.subbuzzShareModalModal {
  z-index: 601;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 2px 2px rgba(170, 170, 170, 0.25);
  margin: 55px auto 55px auto;
  width: 630px;
  height: calc(100% - 110px);
  display: flex;
  flex-direction: column;

  animation-delay: 1s;
  animation-duration: .15s;
  animation-fill-mode: both;
  animation-name: modal__content-scale;

  @media (max-width: $mobile-width) {
    margin: $mobile-padding auto $mobile-margin-bottom auto;
    padding-top: $mobile-padding;
    width: calc(100% - ($mobile-padding * 2));
    height: auto;
  }

  .header, .content, .footer {
    padding: 0 $default-padding;
  }
  .header, .footer {
    height: $header-footer-height;
    min-height:  $header-footer-height;
    max-height:  $header-footer-height;
  }
  .header {
    @media (max-width: $mobile-width) {
      height: $mobile-header-footer-height;
      min-height:  $mobile-header-footer-height;
      max-height:  $mobile-header-footer-height;
      padding: 0 16px;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div:first-child, div:last-child {
      width: 70px;
    }
    div:not(:first-child):not(:last-child) {
      color: $black;
      font-family: Proxima Nova;
      font-weight: 800;
      font-size: 1.75rem; // 28px;
      flex-grow: 1;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (max-width: $mobile-width) {
        font-size: 1.125rem; // 18px;
        font-weight: 700;
      }
    }
    div:last-child {
      text-align: right;
    }

  }
  .content {
    padding: 1.375rem $default-padding 0 $default-padding;
    @media (max-width: $mobile-width) {
      padding: 28px 16px 0 16px;
    }
    flex-grow: 1;
    overflow-y: auto;
    border-top: 1px solid #0000001F;
    border-bottom: 1px solid #0000001F;
    // Override showing share button
    [class^='subbuzz-share_subbuzzShare'] {
      display: none;
    }
  }

  .footer {
    padding: 10px $default-padding;
    display: flex;
    justify-content: flex-end;
  }

  .closeButton {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.5rem; // 24px;
    svg {
      width: 20px;
    }
    &:focus {
      border: 2px solid $blue;
    }
  }

  // Uses same style as Native Share button
  .readArticleButton {
    @extend .nativeShareButton;
    &:focus {
      border: 2px solid $black;
    }
  }
}

// Scale up animation for modal content
@keyframes modal__content-scale {
  from {
    opacity: 0;
    transform: scale3d(.6, .6, .6);
  }
  50% {
    opacity: 1;
  }
}

@keyframes modal__content-fade {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
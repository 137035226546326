@import '../../../styles/lib/helpers';

a.backToTop {
  display: none;

  @include breakpoint('sm') {
    display: block;
    left: 2rem;
    right: auto;
  }
}

.breakingBarWrapper {
  @include breakpoint('sm') {
    >div {
      margin-bottom: 1.5rem;
    }
  }
}

@import "../../styles/lib/helpers";

.newsFooter {
  .reporterCardList {
    @include breakpoint("sm") {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
  .reporterCardItem {
    margin-bottom: 2rem;
    background-color: $news-c-gray-warm;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #f1f1ec;
    overflow: hidden;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: $space-2;
    }

    @include breakpoint("sm") {
      margin-bottom: $space-2;
      &:not(:only-child) {
        flex: 0 0 auto;
        width: calc(50% - (#{$space-2}/2));
      }
    }
  }
}
.newsByline {
  margin-bottom: 0.75rem;
  .bylineContainer {
    display: inline-flex;
    align-items: center;
  }
  .avatar {
    height: 58px;
    width: 58px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.75rem;
    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .bylineText {
    color: $news-c-gray-darken;
  }
  .position {
    font-size: $news-text-7;
    line-height: 1.25;
  }
  .bylineName {
    color: $black;
    font-family: $pensum;
    font-size: $text-6;
    line-height: 1.2;
    font-weight: $news-w-medium;
    text-decoration: none;
  }

  .bylineName:hover {
    text-decoration: underline;
  }

  .headlineByline {
    text-decoration: none;
    &:hover {
      .bylineName {
        color: $news-c-gray-dark;
      }
    }
  }
}

.newsFooterText {
  color: $news-c-black;
  margin-bottom: 1rem;
  & > a {
    color: $news-c-black;
    word-break: break-word;
    &:hover {
      color: $news-c-gray-dark;
    }
  }
}

.userBio {
  font-size: $news-text-5;
  line-height: 1.5;
  .newsFooterText:last-child {
    margin: 0;
  }
}

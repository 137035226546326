
@import "../../../../styles/lib/variables";

.returnLink {
  font-weight: 700;
  font-size: $text-6;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.returnIcon {
  fill: $blue;
  width: 0.75rem;
  margin-right: 0.25rem;
}
.commentOn {
  background: linear-gradient(to right, #e40c78, #6645dd);
  color: white;
  text-transform: uppercase;
  display: inline-block;
  font-size: $text-6;
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius;
  margin-bottom: 0.5rem;
}
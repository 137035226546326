@import "../../styles/lib/variables";

.subbuzzShare {
  position: absolute;
  right: 8px;
  bottom: 8px;

  @media (max-width: map-get($breakpoints, 'md')) {
    &.share_enabled_v2 {
      left: 8px;
      top: 8px;
      right: initial;
      bottom: initial;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  border-radius: 50px;
  color: #FFF;
  background: #e40c78;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 3px 10px rgba(0,0,0,0.3);

  .link {
    padding: 7px 20px 7px 15px;
    font-weight: bold;
  }

  .close {
    padding: 7px 10px 7px 15px;
    font-size: 24px;
    border-left: 1px solid rgba(255,255,255,0.5);
  }
}


@import "../../styles/lib/helpers";
@import "../../styles/lib/variables";

.retailProductsGridWrapper {
  margin: 16px 0;

  .retailProductsGrid {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;

    > div {
      flex: 1;
    } 

    @media (max-width: 767px) {
      flex-wrap: wrap;

      > div {
        flex: 0 0 calc(50% - 16px);
      }
    }   

    a {
      text-decoration: none;
    }
  }
}

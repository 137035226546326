@import "../../styles/lib/variables";
@import "../../styles/lib/helpers";

$package-colors: (yellow: (theme: "light",
    color: #ffee02),
  blue: (theme: "dark",
    color: #1581ff),
  green: (theme: "dark",
    color: #69af14),
  pink: (theme: "dark",
    color: #f43192),
  black: (theme: "dark",
    color: #222),
  orange: (theme: "dark",
    color: #f99126),
);

.text {
  font-weight: $bold;
}

.link {
  text-decoration: none;
  color: $black;

  &:hover {
    color: $blue;
  }
}

.img {
  width: 100%;
  background-color: $white;

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.wrap {
  padding: $space-2;
  background-color: $white;
}

.header {
  padding: $space-2 $space-2 $space-2 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;

  @include breakpoint("sm") {
    flex-direction: row;
  }
}

.splash {
  width: 100%;

  @include breakpoint("sm") {
    width: calc(49% - 1px);
  }

  .dek {
    font-size: $text-6;
    color: $black;
    padding-top: $space-1;
    padding-bottom: $space-3;

    @include breakpoint("sm") {
      padding-top: $space-05;
      padding-bottom: $space-2;
    }
  }
}

.items {
  margin-left: -16px;
  margin-right: -16px;

  @include breakpoint("sm") {
    margin: 0;
  }
}

.item {
  padding-bottom: $space-1;

  .text {
    font-size: $text-6;
  }
}

.headerWrap {
  align-items: center;
  display: flex;
  flex-flow: column;

  @include breakpoint("sm") {
    align-items: flex-start;
  }
}

.headerText {
  color: $black;
  font-weight: $bold;
  text-align: center;
  line-height: 42px;
  font-size: 26px;
  margin-bottom: $space-05;

  @include breakpoint("sm") {
    text-align: left;
    line-height: 52px;
    font-size: 42px;
  }
}

.fireIcon {
  margin-bottom: -7px;
  margin-right: -4px;
  margin-left: -9px;
  height: 38px;

  @include breakpoint("sm") {
    height: 53px;
    margin-bottom: -12px;
    margin-right: 0;
  }

}

.packageSubTitle {
  font-size: $text-6;
  padding-bottom: $space-05;
}

.ctaTopWrap {
  display: none;

  @include breakpoint("sm") {
    display: flex;
    justify-content: flex-end;
  }
}

.rightArrow {
  vertical-align: middle;
  width: 14px;
  height: 14px;
}

.ctaTop {
  text-shadow: 0 1px 2px rgba(0, 0, 0, .15);
  align-items: center;
  font-weight: $bold;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
}

.ctaBottom {
  display: flex;
  align-items: center;
  font-size: $text-5;
  font-weight: $bold;
  width: 100%;
  line-height: 1.5rem;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  white-space: normal;
  text-align: center;
  margin-top: $space-2;
}

.ctaIcon {
  margin-left: 4px;

  path {
    fill: $blue;
  }
}

@each $color_name,
$data in $package-colors {
  .#{"" + $color-name} {
    border-top: none;
    background-color: map-get($data, "color");
    background-image: url('./themed-backgroud.png');

    .ctaTop {
      @if map-get($data, "theme")=="dark" {
        color: $white;
      }

      @if map-get($data, "theme")=="light" {
        color: #222;
      }
    }

    .rightArrow {
      @if map-get($data, "theme")=="dark" {
        fill: $white;
      }
    }

    .headerText,
    .packageSubTitle {
      @if map-get($data, "theme")=="dark" {
        color: $white;
      }

      @if map-get($data, "theme")=="light" {
        color: #222;
      }
    }

    .ctaBottom {
      @if map-get($data, "theme")=="dark" {
        color: map-get($data, "color");
      }

      @if map-get($data, "theme")=="light" {
        color: #222;
      }

      &:hover,
      &:active {
        background-color: map-get($data, "color");
        color: #fff;

        @if map-get($data, "theme") !="dark" {
          background: #222;
        }
      }
    }


    .countdownNum,
    .countdownUnit {
      @if map-get($data, "theme")=="dark" {
        color: #fff;
      }

      @if map-get($data, "theme")=="light" {
        color: #222;
      }
    }

    .countdownItem {
      &::before {
        @if map-get($data, "theme")=="dark" {
          background: #222;
          opacity: .2;
          border-color: rgba(0, 0, 0, .2);
        }

        @if map-get($data, "theme")=="light" {
          background: #fff;
          opacity: .5;
          border-color: rgba(255, 255, 255, .2);
        }
      }
    }
  }
}

.default {
  padding-bottom: $space-2;

  .headerText {
    color: $black;
    font-size: $text-5;
    line-height: 1.3rem;
  }

  .splash {
    @include breakpoint("sm") {
      width: 100%;
      margin-bottom: $space-3;
    }

    .img {
      @include breakpoint("sm") {
        width: calc(50% - #{$space-1});
      }
    }

    .text {
      font-size: 1.375rem;
      padding-top: $space-2;

      @include breakpoint("sm") {
        padding-top: 0;
      }
    }

    .textWrap {
      @include breakpoint("sm") {
        width: calc(50% - #{$space-1});
      }
    }

    .link {
      @include breakpoint("sm") {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .items {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
  }

  .item {
    flex-basis: 11.25rem;
    flex-shrink: 0;
    margin-right: $space-2;

    @include breakpoint("sm") {
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }

    &:first-child {
      margin-left: $space-2;

      @include breakpoint("sm") {
        margin-left: 0;
      }
    }

    .img {
      margin-bottom: $space-05;
      background-color: $white;
    }
  }

  .ctaBottom {
    color: $blue;

    &:hover {
      color: #083782;

      .ctaIcon {
        path {
          fill: #083782
        }
      }
    }

    @include breakpoint("sm") {
      display: flex;
      align-items: center;
      border: 0;
      justify-content: flex-start;
      padding: 0;
      background: none;
    }
  }
}

.countdown {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: $space-2;

  @include breakpoint("sm") {
    margin-top: 0;
  }
}

.countdownItem {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-left: .3rem;
  margin-right: .3rem;
  padding-left: 0;
  padding-top: .3rem;
  padding-bottom: .5rem;
  padding-right: 0;

  &:first-of-type {
    margin-left: 0;
    padding-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
    padding-right: 0;
  }

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid;
  }
}

.countdownNum {
  display: block;
  position: relative;
  z-index: 2;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  min-width: 50px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;

  @include breakpoint("sm") {
    font-size: 24px;
    line-height: 36px;
  }
}

.countdownUnit {
  display: block;
  position: relative;
  z-index: 2;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  line-height: 17px;
  font-size: 14px;
  text-align: center;
  letter-spacing: .1em;
}
@import "../../styles/lib/helpers";

.topicPageLink {
  font-size: 0.875rem;
  text-decoration: none;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: #222;
    }
  }
  &:hover {
    .pageName {
      text-decoration: underline;
    }
  }
  .pageName {
    color: $purple;
    font-weight: 700;
    padding-left: 0.25rem;
  }
  .caret {
    height: 12px;
    margin-right: 0.25rem;
  }

  &.top {
    margin-top: 0.75rem;
    @include breakpoint('sm') {
      margin-top: 2.25rem;
    }
  }
  &.bottom {
    margin-left: 0.25rem;
  }
}

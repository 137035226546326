@import "../../styles/lib/helpers";

.topics {
  font-size: $news-text-5;
  font-weight: $news-w-medium;
  padding: 2rem 0;
  border-bottom: 2px dotted $news-c-black;
  border-top: 2px dotted $news-c-black;
  margin-bottom: 2rem;

  .heading {
    font-size: 20px;
    padding-bottom: 1rem;
    a {
      text-decoration: underline;
      color: $news-c-black;
      @extend  %news-link-style;
    }
  }
  li {
    display: inline-block;
    margin: 5px 10px 5px 0;
    a {
      display: block;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 20px;
      border: 1px solid $news-c-black;
      color: $news-c-black;
      padding: 5px 10px;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.25rem;
      &:hover {
        color: $white;
        background-color: $news-c-black;
        text-decoration: none;
      }
    }
  }
}

@import "~@buzzfeed/adlib/core/sass/mixins";
@import "../../../../styles/lib/helpers";

$primary-color-variable: --lookbook-headline-background;
$text-color-variable: --lookbook-headline-text-color;
$subbuzz-header-background-variable: --lookbook-sb-header-background;

$image-offset: 40px;
$image-offset-xl: 64px;

// in cases where we have 2 images in the header
// show both of them on screens larger than this breakpoint
$second-image-breakpoint: 1000px;

.headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.timestamp {
  align-self: flex-start;

  @include breakpoint('md') {
    align-self: center;
  }
}

.paidPostBreadcrumb {
  background: var($primary-color-variable);
  color: var($text-color-variable);
  border-radius: 3px;
}

.title {
  font-size: 48px;
  max-width: 800px;
  margin: auto;
  margin-top: 28px;

  @include breakpoint('md') {
    text-align: center;
  }
}

.imageFullWidthContainer {
  margin-top: 32px;
  margin-bottom: 35px;
  position: relative;
  width: 100vw;
}

.imagesContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  padding: 0 16px;

  @include breakpoint('xl') {
    padding: 0;
  }

  .imageWrapper {
    display: inline-block;

    img {
      max-width: 100%;
    }

    .imageCaption {
      color: var($text-color-variable);
      text-align: left;
      font-style: italic;
      font-size: 12px;
      line-height: 16px;
      margin: 8px 0 16px;
    }
  }

  &.desktop {
    display: none;
    @media (min-width: $second-image-breakpoint) {
      display: flex;
    }
  
    .imageWrapper:nth-child(2) {
      margin-left: 24px;
  
      .imageCaption {
        visibility: hidden;
      }
    }
  }

  &.mobile {
    @media (min-width: $second-image-breakpoint) {
      display: none;
    }
  }
}

.imageBackgroundContainer {
  background: var($primary-color-variable);
  color: var($text-color-variable);
  width: 100%;
  text-align: center;
  z-index: -1;
  position: absolute;
  bottom: 0;
  height: calc(100% - $image-offset);

  @include breakpoint('xl') {
    height: calc(100% - $image-offset-xl);
  }
}

.bylineContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  @include breakpoint('md') {
    margin-bottom: 40px;
  }
}

.description {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 40px;

  @include breakpoint('md') {
    font-size: 26px;
    line-height: 31px;
  }
}

.contentWidth {
  max-width: 600px;
  margin: auto;
  text-align: left;
}

.actionBar {
  margin-bottom: 0 !important;
}

:export {
  primaryColor: $primary-color-variable;
  textColor: $text-color-variable;
  subbuzzHeaderBackground: $subbuzz-header-background-variable;
}

@import "../../styles/lib/helpers";

.comments {
  margin-top: $space-4;
}

.commentsHeader {
  font-size: 24px;
  font-family: 'Calistoga';
}

.commentsWrapper.condensedComments {
  padding: 24px;
  border-radius: 20px;
  margin-bottom: 24px;
  background-color: $purple-lightest;
  @include breakpoint("stickyHeader") {
    margin-bottom: 4rem;
  }
}

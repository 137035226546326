@import "../../../styles/lib/helpers";

.bfElementsWrapper {
  margin-top: 1.5rem;
  @include breakpoint('lg') {
    margin-bottom: 4.5rem;
  }
}
.bfElementsWrapperQuiz {
  @include breakpoint('lg') {
    margin-bottom: 4.5rem;
  }
}

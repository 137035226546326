@import "../../styles/lib/helpers";
.ctaButton {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border: 0;
  border-radius: 29px;
  font-family: $proxima;
  font-size: 1rem;
  font-weight: $bold;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  background-color: $color-feed-purple;
  transition: background-color 0.3s;
  &:hover {
    background-color: $color-feed-pink;
  }
  @include breakpoint('sm') {
    flex-grow: 0;
  }
}

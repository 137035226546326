@import "../../styles/lib/variables";

.shareBottom {
    margin-bottom: 48px;

    .shareCta {
        font-size: 22px;
        font-weight: 700;
        color: $accentPink;
        margin-bottom: 16px;
    }

    .shareBar {
        display: flex;
        align-items: center;
    }

}
@import "../../styles/lib/helpers";

.main {
  position: relative;
  padding: 16px;
  &.buzzfeed {
    @include breakpoint("sm") {
      padding: 0;
    }
  }
}

.article {
  display: flex;
  justify-content: center;
  width: 100%;

  &.default {
    @include breakpoint("lg") {
      justify-content: flex-start;
      max-width: 1000px;
      margin: 0 auto;
    }

    @media(min-width: 1100px) {
      max-width: calc(950px + 5.5rem);
    }
  }

  &.buzzfeed {
    .content {
      max-width: 600px;
    }
  }

  &.buzzfeed_news {
    max-width: 1166px;
    margin: 0 auto;
    .content {
      @include breakpoint("lg") {
        max-width: 600px;
      }
      @media(min-width: 1200px) {
        max-width: 740px;
      }
    }
    @include breakpoint("lg") {
      justify-content: space-between;
    }
    &.wide {
      @include breakpoint("lg") {
        justify-content: center;
        .content {
          max-width: 640px;
        }
      }
      @media(min-width: 1200px) {
        .content {
          max-width: 792px;
        }
      }
    }
  }

  @include breakpoint("sm") {
    &.spinner-quiz {
      overflow-x: hidden;
    }
  }
}

.content {
  width: 100%;
}

.shareTop {
  display: none;

  @include breakpoint("stickyHeader") {
    display: block;
  }
}
.buzzfeed_news {
  .shareTop {
    display: block;
  }
  :global {
    .skipToFooter:focus {
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 7.5%;
      clip: auto;
      position: relative;
      left: auto;
    }
  }
}

.commentsMain {
  .commentsWrapper {
    min-height: 400px;
  }
  .communityFooterWrapper {
    padding-top: 1rem;
  }
}

.discussionCommentsWrapper {
  min-height: 400px;
}
.discussionTitleWrapper {
  position: relative;
  background-color: var(--color-feed-yellow, $color-feed-yellow);
  border-radius: 8px 8px 22px 22px;
  margin-top: 1rem;
  padding: 16px 28px;
  align-items: center;
  z-index: 2;

  > * {
    position: relative;
    z-index: 3;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 48px;
    bottom: -16px;
    left: 0;
    background-color: var(--wave-color, $color-aqua);
    -webkit-mask-image: url('../PromoBar/img/waves/wave-size-8-bg.svg'); // todo
    mask-image: url('../PromoBar/img/waves/wave-size-8-bg.svg');
    -webkit-mask-position: 0 100%;
    mask-position: 0 100%;
    -webkit-mask-size: 100% 48px;
    mask-size: 100% 48px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    aspect-ratio : 1 / 1;
  }

  @include breakpoint('sm') {
    border-radius: 8px 22px 22px 8px;

    &:after {
      width: auto;
      height: 100%;
      top: 0;
      bottom: auto;
      right: -16px;
      left: auto;
      transform: rotate(-90deg);
      mask-position: 100% 100%;
    }
  }
  
  h1 {
    font-family: Proxima Nova;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000;
    text-decoration: none;
    margin: 8px auto;

    @include breakpoint("md") {
      font-size: 32px;
      line-height: 39px;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $black;
    margin: 8px auto;
  }
}

.discussionShare {
  margin: 2rem 0 3rem 0;

  h2 {
    color: $color-feed-pink;
    padding: 0.5rem;
  }

  .shareBar {
    justify-content: flex-start;
  }

  ul {
    justify-content: flex-start !important;
  }
}

.discussionShareNewButton {
  margin-left: 8px;
}

.discussionBottomUnit {
  max-width: 1070px !important;
}

.flameIcon {
  text-transform: uppercase;
  margin: 0 3px;
}

.headlineContainer {
  margin-top: 2rem;
  font-size: 14px;
  line-height: 14px;

  a {
    color: #6645DC;
    font-family: Calistoga;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.commentsAndShare {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  @include breakpoint("md") {
    padding-top: 2rem;
  }

  .discussionTitleWrapper & {
    padding-top: 0.25rem;

    @include breakpoint("sm") {
      margin-bottom: 0.25rem;
    }
  }

  .nativeShare {
    background: none;
    border: none;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .commentsBubble {
    position: relative;
    top: 2px;
    margin-right: 10px;
    @include breakpoint("md") {
      width: 18px;
      height: 18px;
    }
  }

  .nativeShareIcon {
    margin-top: auto;
 }

  .numberOfComments {
    color: $color-feed-pink;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    flex-grow: 1;

    @include breakpoint("md") {
      font-size: 26px;
      line-height: 26px;
    }
  }
}

.commentsAndShare.nativeShareVariant {
  align-items: center;
}
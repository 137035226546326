@import "../../styles/lib/helpers";

.heading {
  display: block;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;

  @include breakpoint("stickyHeader") {
    display: block;
  }
}

.shareBar {
  opacity: 0;
  transition: opacity 200ms ease;
  list-style-type: none;
  padding-left: 0 !important;
  li {
    display: inline-block;
  }

  // prevent flash of unstyled svgs in Firefox
  &.loaded {
    opacity: 1;
  }

  &.subbuzz {
    display: flex;
  }

  &.pagelevel.buzzfeed,
  &.pagelevelSticky {
    justify-content: flex-end;
    width: auto;
    height: 35px;
    flex: 1;
    display: flex;

    @include breakpoint("stickyHeader") {
      &.large {
        height: 42px;
      }
    }
  }
  &.pageLevelOutlineThick.buzzfeed {
    justify-content: flex-end;
    width: auto;
    flex: 1;
    display: flex;
    align-items: center;
    li:not(:first-child) {
      padding-left: 8px;
    }
  }
  &.pagelevelList {
    li {
      display: block;
    }
  }

  &.pagelevel.buzzfeed_news,
  &.pageLevelOutlineThick.buzzfeed_news {
    white-space: nowrap;
    margin: 0 1rem 1rem 0;
    li {
      &:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }
}

.shareBar.buzzfeed .shareButton.pagelevel {
  &.top_share_bar {
    margin-left: 10px;
    margin-right: 0.25rem;
  }

  &.bottom_share_bar {
    margin-right: 13px;
  }
}

.heading + .shareBar.pagelevel {
  justify-content: flex-start;
}

.shareButton.subbuzz {
  margin-right: 0.5rem;
}

.shareBarContainer {
  position: absolute;
  right: 15px;
  top: 8px;
}
.shareButton.pinterestOverlay {
  padding: 0 8px;
  background: $pinterest;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  height: 40px;
  &:hover {
    cursor: pointer;
    background: darken($pinterest, 10%);
    // 'Save' button text
    & a:first-child span {
      color: $white;
    }
  }
  &:active {
    background: darken($pinterest, 15%);
  }
  // 'Save' button text
  & a:first-child span {
    color: $white;
    margin: 0 10px;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      color: $white;
    }
  }

  // pinterest icon
  & a:first-child svg:first-child {
    fill: $white;
  }

  z-index: 200;
  opacity: 0;
  transition: opacity 0.3s;
  &.show {
    opacity: 1;
  }
}

.floatingShareButton {
  $zIndex: 100;

  display: block;
  overflow: hidden;
  padding-right: 20px;
  position: fixed;
  bottom: 4rem;
  right: 0;
  z-index: $zIndex;

  &.inactive {
    display: none;
  }

  button {
    transform: translateX(145%);
    transition: transform 400ms ease;
  }

  &:not(.visible) > button {
    transform: translateX(145%);
  }
  &.visible > button {
    transform: translateX(0%);
  }

}

@import "../../styles/lib/helpers";

.container {
  margin-bottom: 28px;
  background-color: $shoppingOffWhite;
  padding: 16px;
  @include breakpoint("lg") {
    padding: 16px 28px;
    margin-bottom: $space-4;
  }
}

.text {
  font-size: $text-7;
  line-height: 1.3;

  @include breakpoint("lg") {
    font-size: $text-6;
    line-height: 1.2;
  }
}

@import "../../../../styles/lib/helpers";

.timestamp {
  font-size: $text-6;
}

.timestampNews {
  color: $news-c-gray-dark;
  font-size: $news-text-7;
  line-height: 1.4;
  time {
    display: flex;
    flex-wrap: wrap;
  }
  .timeDisplay {
    width: 100%;
  }
}

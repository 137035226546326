@import "../../styles/lib/helpers";
@import "../../styles/lib/variables";

// ------------------------------------------------------------------
// This file contains styles for each destination
// Currently these are all included as part of the page's critical css
// as there is only a small amount of unshared code.
// ------------------------------------------------------------------

.buzzfeed {
  @include font-size('text-5');
  font-family: $proxima;
  color: $black;
  line-height: 1.25;

  h1 {
    @include font-size('text-3');
    font-weight: $bold;
    @include breakpoint("sm") {
      @include font-size('text-1');
      line-height: 1.05;
    }
  }

  h2 {
    @include font-size('text-4');
    font-weight: $bold;
    @include breakpoint("sm") {
      @include font-size('text-3');
    }
  }
}

.buzzfeed_news {
  color: #222;
  font-family: $basier;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include generate-breakpoint-prefixes {
    &font-serif {font-family: $pensum;}
    &font-sans {font-family: $basier;}
  }

  :global {
    ::selection {
      background: $news-c-red;
      color: $news-c-white;
    }

    h1 {
      @include font-size('news-text-2');
    }

    .buzz--feature,
    .buzz--long {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: bold;
        font-family: $basier;
        a {
          @include text-underline($news-c-gray-dark);
          color: $news-c-gray-dark;
          &:hover,
          &:focus {
            color: $news-c-black;
            text-decoration: none;
          }
          &:visited {
            color: $news-c-gray-dark;
            text-decoration: none;
          }
        }
      }
    }
  }
}

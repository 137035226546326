@use "sass:math";
@import "../../styles/lib/helpers";

.link {
  color: $black;
  text-decoration: none;
  &:hover,
  &:focus {
    .title {
      color: #5246F5 !important;
    }
  }
}

.timeAgo {
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #757575;
  }
}

.trending {
  margin: 32px auto;
  max-width: 1136px;
  padding: $space-2 16px 0;

  @media (max-width: 640px) {
    padding: 0;
  }

  .header {
    font-family: Calistoga;
    font-size: 28px;
    font-weight: 400;
    line-height: 33.6px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5246f5;
    margin-bottom: 1rem;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 1rem;
    /* padding: 1rem; */
  }

  .cardWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    margin-left: 2rem; /* Added margin to create space for category on the left */

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0; /* Remove margin for mobile */
    }
  }

  .category {
    font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: uppercase;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5246f5;
    margin-right: 1rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    position: absolute;
    left: -2rem;
    top: 0;
    transform: rotate(180deg); /* Ensures text reads bottom-up */

    @media (max-width: 768px) {
      font-size: 12px; /* Mobile styles */
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.02em;
      margin-top: 0.5rem;
      margin-right: 0;
      margin-bottom: 0.5rem;
      order: 2;
      writing-mode: horizontal-tb;
      text-orientation: mixed;
      transform: none; /* Reset for mobile */
      position: static;
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: transparent;
    overflow: hidden;
    transition: transform 0.2s;
    padding: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:hover {
      transform: translateY(-5px);
    }
  }

  .imageWrapper {
    position: relative;
  }

  .image {
    width: 209px;
    height: auto;
    object-fit: cover;
    margin-right: 1rem;
    flex-shrink: 0;
    border-radius: 8px;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      order: 1;
    }
  }

  .number {
    position: absolute;
    top: 0; /* Tightly tucked into the corner */
    left: 0; /* Tightly tucked into the corner */
    background: #b1ecfd; /* Updated background color */
    color: #5246f5; /* Updated text color */
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
  }

  .title {
    font-family: Proxima Nova;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #222222;
    flex: 1;

    @media (max-width: 768px) {
      font-size: 14px; /* Mobile styles */
      font-weight: 700;
      line-height: 16.8px;
      order: 3;
      /* margin-top: 0.5rem; */
    }
  }

  @media (max-width: 768px) {
    .cardWrapper {
      flex-direction: column;
    }

    .category {
      margin-top: 0.5rem;
      margin-right: 0;
      margin-bottom: 0.5rem;
      order: 2;
    }

    .title {
      order: 3;
    }
  }
}

@import "../../styles/lib/helpers";

.footerCommunityBanner {
  background: url('../../public/static/images/communityPromoBanner/bf_pattern_waves_silveryellow_horizontal.png') 0 0 no-repeat;
  background-size: 100% 27px;
  padding-left: $space-2;
  padding-right: 28px;
  padding-bottom: $space-3;
  margin-bottom: 4rem;
  border-radius: 4px;
  background-color: $promo-banner-bg-color;
  @include breakpoint('sm') {
    background: url('../../public/static/images/communityPromoBanner/bf_pattern_waves_silveryellow.png') 0 50% no-repeat;
    background-size: auto 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-right: $space-1;
    border-radius: $space-1;
  }
}
  .flexContainer{
    display: flex;
    justify-content: space-between;
    .flexContainerTitle{
      padding-top: 3.5rem;
      font-size: $text-5;
      font-weight: 800;
      @include breakpoint('sm') {
        flex-basis: 50%;
        padding-top: $space-3;
      }
    }
    .flexContainerImgWrapper {
      padding-top: $space-1;
      display: none;
      @include breakpoint('sm') {
        display: block;
      }
      .flexContainerImg {
        width: 257px;
      }
    }
  }
  .buttonsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $space-2;
    margin-top: $space-3;
    @include breakpoint('sm') {
      flex-direction: row;
      gap: 0;
      margin-top: -15px;
    }
    .loginBtn {
      cursor: pointer;
      text-decoration: none;
      border: none;
      color: #fff;
      border-radius: 1000px;
      line-height: 25px;
      background-color: $promo-banner-button-color;
      padding: 10px $space-2;
      margin-top: 6px;
      margin-bottom: 6px;
      width: 100%;
      font-weight: $bold;
      font-size: $news-text-5;
      text-align: center;
      @include breakpoint('sm') {
        width: 197px;
        margin-right: 32px;
      }
      &:hover {
        opacity: .8;
      }
    }

    .learnMoreBtn {
      @extend .loginBtn;
      @include breakpoint('sm') {
        width: 256px;
        margin-right: 0;
      }
    }
  
    .promoLink {
      color: $promo-banner-button-color;
      font-weight: $bold;
      text-decoration: none;
      font-size: $news-text-5;
      &:hover {
        text-decoration: underline;
        opacity: .8;
      }
    }
  }
  
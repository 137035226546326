@import "../../styles/lib/helpers";

.container {
  > *:not(:last-child) {
    border-bottom: $border;
    padding-bottom: $space-2;
    margin-bottom: $space-2;
  }

  :global {
    .fb-page {
      /**
       * FB page plugin styles the widget at the plugin's default height of 500px.
       * Reserving that space ahead of time to reduce layout shift.
       */
      height: 500px;
    }
  }
}

@import "../../styles/lib/helpers";

.discussionImage {
  margin: 16px auto;

  picture {
    position: relative;
    width: 100%;
    background-color: rgba(0, 0, 0, .05);

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 8px;      
    }  
  }

  .attribution,
  .attribution a {
    margin-top: 16px;
    color: #666;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
  }
}

@import "../../styles/lib/helpers";

.headline {
  position: relative;
  margin: $space-1 0 $space-3;

  @include breakpoint("sm") {
    margin: $space-3 0;
  }

  &.spinner-quiz {
    padding: 10px 0 26px;
    margin-top: 0;

    @include breakpoint("sm") {
      padding: 40px 0;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      width: 100vw;
      height: calc(100% + 16px);
      background-image: url('../../public/static/images/spinner-quiz-bg.png');
      transform: translateX(-16px) translateY(-26px);
      background-size: cover;
      background-repeat: repeat-x;

      @include breakpoint("sm") {
        top: 0;
        height: 100%;
        width: 200vw;
        transform: translateX(-50%);
        background-size: contain;
      }
    }

    .paidPostBreadcrumb {
      color: #222;
    }

    *:not(.paidPostBreadcrumb) {
      color: #fff !important;
    }
  }
  &.buzzfeed {
    .description {
      html[lang="ja"] main & {
        @include font-size('text-5');
      }

      a {
        color: $blue;
        text-decoration: none;

        &:hover {
          color: #093c8f;
          transition: color .15s ease 0s;
        }
      }
    }
    .container {
      margin-bottom: $space-2;
    }
  }
  &.buzzfeed_news {
    margin-bottom: 1rem;
    .description {
      line-height: 1.44;
      a {
        @extend %news-link-style;
      }
    }
    .container {
      margin-bottom: $space-3;
    }
  }
}

.description {
  @include font-size('text-5');
}

.title {
  margin-bottom: $space-1;
}

.hideTitle {
  @include breakpoint("sm") {
    @include screen-reader-only;
  }
}

.paidPostBreadcrumb {
  background-color: $gray-1;
  font-size: $text-6;
  font-weight: $bold;
  padding: $space-05 $space-2;
  border-radius: 12px;
  margin-right: $space-1;
}

.breadcrumbContainer {
  display: inline-block;
  ol {
    display: inline-block;
  }

  @media (max-width: 40rem) {
    display: block;
  }
}
.breadcrumbContainerNews {
  text-transform: uppercase;
  a {
    line-height: 24px;
    font-weight: 700;
    @extend %news-link-style;
    color: $news-c-red-2;
    font-family: 'Basier Square Narrow';
  }

  a:hover {
    color: $news-c-red-2;
  }

  li:nth-child(2)::before {
    content: "•";
    color: $news-c-red-2;
    margin: 0 5px;
    font-weight: 700;
    font-size: 1rem;
  }
}

.middot {
  margin: 0 $space-1;

  @media (max-width: 40rem) {
    display: none;
  }
}

.disclaimer {
  background-color: $red-1;
  padding: $space-2;
  font-size: $text-6;
}

.sharedResultHeadlineWrapper {
  margin: 18px 0 24px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  align-items: center;

  @include breakpoint("md") {
    margin: 48px 0;
  }
}

.sharedResultBadge {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 18px;

  p {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    margin-top: 2px;
    margin-left: 6px;
  }

  @include breakpoint("md") {
    margin-bottom: 24px;

    p {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -1px;
      margin-top: 4px;
      margin-left: 16px;
    }
  }
}

.sharedResultTitle {
  flex: 1;
  font-weight: 700;
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: bold;

  a {
    color: #0F65EF;
  }

  @include breakpoint("md") {
    font-size: 32px !important;
    line-height: 38px !important;
    letter-spacing: -1px;
  }
}


@import "../../styles/lib/helpers";

$border: 2px;
$blue-dark: #093c8f;
$blue-light: #3594cd;

.trendingProducts {
  background: #FAF8F8;
  padding: 1rem 1.5rem 0 1rem;
  position: relative;
  border: 2px solid $black;
  box-shadow: 5px 5px 0px 0px $black;
  margin: $space-4 0 $space-6;

  @media (min-width: 23rem) {
    padding: 1rem 1rem 0 1rem;
  }

  @include breakpoint("lg") {
    box-shadow: 15px 15px 0px 0px $black;
    padding: $space-3;
    padding-bottom: 0;

    &::after {
      border-radius: 3px;
      left: -($border);
      right: -($border);
    }
  }

  a {
    font-weight: $bold;
    text-decoration: none;
    color: $blue;

    &:hover,
    &:hover svg {
      color: $blue-dark;
      fill: $blue-dark;
      text-decoration: none;
      transition: color .15s ease 0s, fill .15s ease 0s;
    }
  }

  .contentWrapper {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    .title {
      align-items: center;
      display: flex;
      margin-bottom: $space-2;
      text-transform: uppercase;
      position: absolute;
      left: -15px;
      top: 0px;
      background: #FFE3D4;
      border: 1px solid $black;
      box-shadow: 3px 3px 0px 0px $black;
      transform: rotate(-8deg);
      font-size: 14px;
      padding: 12px;

      @include breakpoint("lg") {
        &::before {
          font-size: $text-5;
        }
      }
      @media (min-width: 23rem) {
        font-size: 16px;
      }

      &::before {
        content: '';
      }
    }

    .productList {
      padding-top: 50px;
      list-style: disc;
      margin-left: $space-2;
      padding-bottom: 16px;

      &::after { // clearfix
        content: " ";
        display: table;
      }
    }
  }

  .trendingProduct {
    margin-bottom: $space-3;

    .seeInList {
      margin-top: $space-05;

      a.viewInListBtn {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;

        .downArrow {
          position: relative;
          top: -1px;
          fill: $blue;
          height: 16px;
          width: 16px;
          margin-left: $space-05;
          vertical-align: middle;
        }

        &:hover,
        &:hover svg {
          text-decoration-color: $blue-dark;
          fill: $blue-dark;
        }
      }
    }
  }

  .sponsoredItem {
    list-style-type: circle;
    color: $blue-light;
  }
}

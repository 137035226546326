@import "../../styles/lib/helpers";

.sidebar {
  display: none;
  flex: 0 0 auto;
  width: 300px;
  margin: $space-1 0;

  @include breakpoint("lg") {
    display: block;
    &.buzzfeed {
      margin-left: 5.5rem;
    }
    &.buzzfeed_news {
      margin-left: 3.5rem;
    }
  }

  @media(min-width: 1100px) {
    width: 350px;
  }
}

.sidebarBranded {
  position: relative;
}

@import "../../../../styles/lib/variables";

.twitterTimelineFallback,
.twitterTimelineFallback:not(:last-child) {
  align-items: center;
  background: $twitter;
  border-radius: $border-radius;
  color: #fff;
  display: flex;
  padding: 0.5rem;
  text-decoration: none;

  &:hover, &:focus {
    background: darken($twitter, 0.8);
  }

  svg {
    fill: #fff;
    margin-right: 5px;
  }
}

@import "../../styles/lib/helpers";

.nativeShareButton {
  // reset some default user agent button values to make uniform
  cursor: pointer;
  white-space: nowrap;
  appearance: none;
  user-select: none;
  font-family: inherit;
  padding: 0;
  font-size: 1rem;
  text-decoration: none;
  box-sizing: border-box;

  // native button specific
  height: 44px;
  font-weight: 600;
  display: flex;
  align-items: center;

  border: 2px solid $color-feed-purple;
  color: $color-feed-purple;
  background: #fff;

  &:hover, &:active {
    border-color: darken($color-feed-purple, 20%);
    color: darken($color-feed-purple, 20%);
    .icon {
      fill: darken($color-feed-purple, 20%);
    }
  }

  .icon {
    fill: $color-feed-purple;
  }

  &.android .icon {
    svg {
      height: 14px;
      width: 14px;
    }
  }
  &.ios .icon {
    svg {
      height: 15px;
      width: 15px;
    }
  }

  &.icon_only {
    border-radius: 50%;
    justify-content: center;
    width: 44px;
    .icon {
      margin-top: 4px;
    }
  }
  &.icon_only.ios .icon {
    width: 1rem;
    margin-top: 2px;
  }

  &.standard,
  &.standard_small,
  &.dark {
    padding: 0.5rem 1.5rem;
    border-radius: 22px;
    .icon {
      margin-top: 2px;
      margin-right: 0.375rem;
    }
    &.android svg {
      width: 12px;
      height: 12px;
    }
  }
  &.standard {
    font-size: 1rem; // 16px, to match commentCtaBtn-buzzfeed in react-components
    @media (max-width: 25rem) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media (max-width: 24rem) {
      font-size: 0.875rem;
      padding-left: .75rem;
      padding-right: .75rem;
    }
  }

  &.standard {
    font-weight: 600;
  }

  &.standard_small {
    height: 36px;
    width: 89px;
    font-size: 1rem; // 16px, to match the Start The Discussion button font size
    justify-content: center;
    background: none;
  }

  &.dark {
    height: 36px;
    border: 1px solid #fff;
    background: $black;
    color: #fff;
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem;

    .icon {
      align-self: flex-end;
    }

    .icon svg {
      fill: #fff;
    }

    &:hover, &:active {
      border-color: #fff;
      color: #fff;
      background: lighten($black, 10%);
    }

  }
}

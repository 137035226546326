.promoUnit {
  margin-bottom: 1rem;
}

.promoUnitTitle {
  margin-bottom: 0.5rem;
  a {
    color: #0f65ef;
    text-decoration: none;

    &:hover {
      color: #093c8f;
    }
  }
}

.promoUnitImage {
  img {
    border-radius: 0.5rem;
    width: 100%;
  }
}

@import "../../styles/lib/helpers";

.actionBar {
  &.buzzfeed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /**
     * The min-height is set to the font size of the comments CTA Button to help prevent layout
     * shifts. On smaller screens, only the comments CTA Button is rendered.
     */
    min-height: $text-1;
    margin-bottom: 1rem;

    @include breakpoint('stickyHeader') {
      /**
       * Helps prevent layout shifts on larger screens when the share bar is visible. It needs to be
       * greater than the height (35px) of the Icon components and the comments CTA (36px).
       */
      min-height: 36px;
      margin-bottom: 2rem;
    }
    button {
      /**
       * Emojis seem to have a larger line height compared to regular text. This can cause layout
       * shifts if text is updated to include an emoji since it will increase the height of the
       * container. Setting the line-height to inherit helps align emoji next to the regular text
       * without changing the height.
       */
      line-height: inherit;
      /**
       * Resets the margin of the commentsCtaButton. Handling the margin here on the .actionBar
       * element instead to better manage layout shifts.
       */
      margin-bottom: 0;
    }

    .commentsCtaWrapper {
      display: flex;
      button:not(:last-child) {
        margin-right: 8px;
      }
    }

    @media (max-width: #{map-get($breakpoints, "sm")}) {
      .commentsCtaWrapper {
        flex-grow: 1;
      }
    }
  }

  &.buzzfeed_news {
    display: flex;
  }
}
@import '../../NativeShareButton/nativeShareButton.module.scss';

.copyShareButton {
  @extend .nativeShareButton;
  @extend .dark;
  width: auto;
}

.buttonIcon {
  margin-right: 0.375rem;

  &.share {
    transform: scaleX(-1);
    height: 14px;
    width: 14px;
    margin-bottom: 2px;
    & path {
      stroke: #fff;
    }
  }
  &.shared {
    height: 14px;
    width: 14px;
    fill: #fff;
  }

}

@import "../../../styles/lib/helpers";

.taboolaWrapper {
  margin: 0 auto;
  width: 100%;
}

.taboolaWrapper > div {
  margin: 0 auto 25px !important;
  padding: 0 !important;
  width: 100% !important;
}

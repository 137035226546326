@import "../../../../styles/lib/helpers";

.caret {
  width: .5rem;
  height: .5rem;
  margin-left: $space-05;
  margin-right: $space-05;
}

.comma {
  margin-right: $space-05;
}

.midot {
  margin: 0 $space-1;
}

.breadcrumbItem {
  list-style: none;
  display: inline-block;
}

.breadcrumbNewsItem {
  list-style: none;
  display: inline-block;
}

.breadcrumbNewsItem  .metadata-link {
  color: #d2342a;
}

@import "../../../../styles/lib/helpers";

.headlineByline {
  text-decoration: none;
  font-size: $text-6;
  color: $black;
  margin-right: $space-4;
  display: inline-block;

  &:hover {
    .bylineName {
      color: $blue;
    }
  }

  &.twoAuthors {
    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-right: 0.25rem;
    }
  }
}

.textByline {
  .headlineByline {
    margin: 0;
  }
}

.bylineContainer {
  display: inline-flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  height: 40px;
  margin-right: $space-1;
  width: 40px;
  overflow: hidden;
  @include breakpoint("sm") {
    height: 56px;
    width: 56px;
  }

  picture,
  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &.isAdvertiser {
    border-radius: 4px;
    border: 1px solid #d7d7d7;
  }
}

.text {
  font-size: $text-6;
  text-decoration: none;

  &.isAdvertiser {
    display: none;
  }
}

.desktopHide {
  @include breakpoint("sm") {
    display: none;
  }
}

.desktopShow {
  @media (max-width: map-get($breakpoints, 'sm')) {
    display: none;
  }
}

.adaptiveBylineContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @include breakpoint("sm") {
    flex-direction: row;
  }
}

.internetPointsContainer {
  position: relative;
  height: 10px;
  top: -5px;
  left: 40px;
  max-width: 200px;
  @include breakpoint("sm") {
    height: 20px;
    top: -10px;
    left: 55px;
  }
}

.internetPointsContainerInner {
  display: inline-block;
  position: relative;
}

.communityTeamByline {
  margin-top: $space-2;
  margin-right: 0;
  @include breakpoint("sm") {
    margin-top: 0;
  }
}

.communityTeamBy {
  @include breakpoint("sm") {
    display: block;
  }
}

.bylineWithDateline {
  margin: 0 1rem .5rem 0;
}

.newsHeadlineByline {
  margin-bottom: 0.5rem;
  display: inline-block;
  .bylineWrap {
    display: inline;
    @include breakpoint("sm") {
      display: block;
      margin: 0 1rem 0.5rem 0;
    }
  }
  .text {
    color: $black;
    font-family: $pensum;
    font-weight: $news-w-medium;
    @include breakpoint("sm") {
      display: none;
    }
  }
  .headlineByline {
    margin: 0;
  }
  &.singleHeadlineByline {
    .text {
      display: none;
    }
  }
  @include breakpoint("sm") {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
  }
}

.newsByline {
  display: inline;
  .avatar {
    height: 58px;
    width: 58px;
  }
  .bylineText {
    color: $news-c-gray-dark;
    .text {
      display: none;
    }
  }
  &:not(.singleByline) {
    .avatar,
    .position {
      display: none;
      @include breakpoint("sm") {
        display: block;
      }
    }
  }
  .position {
    font-size: $news-text-7;
    line-height: 1.25;
  }
  .avatar {
    margin-right: 0.75rem;
  }
  .bylineName {
    color: $black;
    font-family: $pensum;
    font-size: $text-6;
    line-height: 1.2;
    font-weight: $news-w-medium;
    text-decoration: none;
  }

  .bylineName:hover {
    text-decoration: underline;
  }

  .headlineByline:hover {
    .bylineName {
      color: $news-c-gray-dark;
    }
  }
  @include breakpoint("sm") {
    display: block;
    margin: 0;
    .headlineByline {
      line-height: 1;
      vertical-align: top;
    }
  }
}

.discussionByline {
  .bylineName {
    font-size: $text-7;
    color: $gray-7;
    text-decoration: none;

    &:before {
      content: 'Asked by ';
      font-weight: $regular;
    }

    &:hover {
      color: $black;
    }
  }
}

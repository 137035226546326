@import "solid-helpers/variables";
@import "../../styles/lib/helpers";

.pageOverlay {
  display: none;

  @include breakpoint("md") {
    display: block;
    position: absolute;
    opacity: .9;
    height: 100%;
    width: 100%;
    background-color: rgba(34, 34, 34, 0.4);
    top: 0;
    z-index: 4;
  }
}
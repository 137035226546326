@import "../../styles/lib/helpers";

.container {
  margin-bottom: 3rem;
}

.quizLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.quizImg {
  min-width: 101px;
  max-width: 101px;
  min-height: 101px;
  max-height: 101px;
  border-radius: 3px;
  object-fit: cover;
  object-position: center;
  margin-right: 12px;
}

.quizTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

@import "../../../../styles/lib/helpers";

.dateline {
  display: flex;
  align-items: center;
  margin-top: $space-1;
  color: $news-c-gray-dark;;
  .dateline_map {
    display: block;
    margin-right: $space-1;
    height: 42px;
    width: 42px;
    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .datelineTitle {
    font-weight: normal;
    font-family: $basier;
    font-size: $news-text-7;
  }
  .datelineName {
    font-family: $pensum;
    font-weight: $news-w-medium;
    font-size: $text-6;
  }
  @include breakpoint("sm") {
    margin: 0 1rem 0.5rem 0;
    .datelineName {
      color: $black;
    }
  }
}

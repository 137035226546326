@use "sass:math";
@import "../../styles/lib/helpers";

.shoppingWrapper {
  margin: 0 auto;
  max-width: 1100px;
  padding-top: $space-2;
  @include breakpoint("sm") {
    padding-top: $space-4;
  }

  ul {
    list-style: none;
  }
}

.bottomShoppingList {
  @include breakpoint("sm") {
    max-width: 75rem;
    padding: 0;
  }
}

.grid, .gridNarrow {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  li {
    margin-bottom: 1rem;
    width: calc(50% - 0.75rem);

    &.fullWidth {
      width: 100%;
    }

    &.collapsed {
      display: none;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr [right-edge];
    grid-auto-flow: dense;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1rem;
    margin-bottom: 2rem;

    li {
      margin: 0;
      width: 100%;

      &.fullWidth {
        grid-column: 1 / span right-edge;
      }
    }
  }
}

.grid {
  @include breakpoint("sm") {
    grid-template-columns: 1fr 1fr 1fr [right-edge];
    grid-column-gap: 1rem;

    li {
      width: calc(#{percentage(math.div(1, 3))} - 0.625rem);
    }
  }

  @supports (display: grid) {
    @include breakpoint("sm") {
      grid-template-columns: 1fr 1fr 1fr [right-edge];
      grid-column-gap: 1rem;
      grid-row-gap: 1.5rem;

      li {
        margin: 0;
        width: 100%;
      }
    }
  }
}

.list {
  margin-bottom: 1rem;

  li {
    margin-bottom: 1rem;

    @include breakpoint("sm") {
      margin-bottom: 1.5rem;
    }
  }
}

.shoppingItemLink {
  color: $black;
  text-decoration: none;
  &:hover,
  &:focus {
    .watchCta {
      transform: rotateX(90deg);
    }
  }

  &.buzzfeed {
    &:hover,
    &:focus {
      color: $blue;
    }

    .stacked {
      .imageWrap {
        border-radius: 4px;
        @include breakpoint("sm") {
          border-radius: 8px;
        }
      }
    }

    .inline {
      .imageWrap {
        border-radius: 4px;
      }
    }
  }
  &.buzzfeed_news {
    @extend %news-link-style;
  }
}

.imageWrap {
  background: $gray-1;
  overflow: hidden;
  position: relative;
  flex: 0 0 auto;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &.big, &.wide {
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 0;
    }
  }

  &.big:before {
    padding-bottom: percentage(math.div(415, 625)); // dblbig image size from CDN
  }

  &.wide:before {
    padding-bottom: percentage(math.div(220, 625)); // dblwide image size from CDN
  }
}

.text {
  @include font-size('text-6');
  display: block;
  font-weight: $bold;

  @include breakpoint("md") {
    @include font-size('text-5');
  }
}

.stacked {
  .imageWrap {
    margin-bottom: 0.5rem;
  }
}

.inline {
  display: flex;

  .imageWrap {
    margin-right: 0.5rem;
    width: 115px;

    &.big {
      height: 75px;
    }

    &.wide {
      height: 40px;
    }

    @include breakpoint("md") {
      margin-right: 1rem;
    }
  }
}

.index {
  display: block;
  padding: 4px 10px;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  color: #fff;
  background: $pink;
  border-radius: 0 8px 0 0;
  min-width: 2rem;
}

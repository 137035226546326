@use "sass:math";
@import "../../styles/lib/helpers";

.mobileTopRight,
.mobileBottomLeft,
.mobileBottomRight {
  display: block;

  @include breakpoint("sm") {
    display: none;
  }
}

.desktopTopRight,
.desktopTopLeft {
  display: none;

  @include breakpoint("sm") {
    display: block;
  }
}

.desktopBottomLeft,
.desktopBottomRight {
  display: none;

  @media (min-width: 1160px) {
    display: block;
  }
}

.mobileTopRight {
  position: absolute;
  top: 0px;
  right: 10px;
}

.mobileBottomLeft {
  position: absolute;
  bottom: -30px;
  left: 0px;
}

.mobileBottomRight {
  position: absolute;
  bottom: -40px;
  right: 0px;
}

.desktopBottomLeft {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.desktopTopRight {
  position: absolute;
  top: 0px;
  right: 0px;
}

.desktopBottomRight {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.desktopTopLeft {
  position: absolute;
  top: 60px;
  left: 0px;
}

.fullWidth {
  width: 100vw;
  background-color: #FFEFE6;
  padding: 16px 0;
  margin-top: 32px;
  margin-bottom: 32px;
  position: relative;

  margin-left: calc(-50vw + 50%); // Center and expand to full width
  margin-right: calc(-50vw + 50%);

  .shoppingWrapper {
    margin: 0 auto;
    max-width: 1136px;
    padding: $space-2 16px 0;

    @include breakpoint("lg") {
      padding: $space-2 16px 0;
    }

    ul {
      list-style: none;
    }

    h2 {
      color: #5246F5;
      font-family: Calistoga;
      font-size: 24px;
      font-weight: 400;
      line-height: 33.6px;
      letter-spacing: 0.01em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      @include breakpoint("sm") {
        font-size: 28px;
      }
    }
  }
}



.bottomShoppingList {
  @include breakpoint("sm") {
    max-width: 75rem;
    padding: 0;
  }
}

.grid, .gridNarrow {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  li {
    margin-bottom: 1rem;
    width: calc(50% - 0.75rem); // Default: 2 columns

    &.fullWidth {
      width: 100%;
    }

    &.collapsed {
      display: none;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Mobile: 2 columns
    grid-auto-rows: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1rem;
    margin-bottom: 2rem;

    li {
      margin: 0;
      width: 100%;

      &.fullWidth {
        grid-column: 1 / -1;
      }
    }

    @include breakpoint("sm") {
      grid-template-columns: repeat(3, 1fr); // Desktop: 3 columns
      grid-column-gap: 1rem;
      grid-row-gap: 1.5rem;

      li {
        width: 100%;
      }
    }
  }
}

.grid {
  @include breakpoint("sm") {
    grid-template-columns: 1fr 1fr 1fr [right-edge];
    grid-column-gap: 1rem;

    li {
      width: calc(#{percentage(math.div(1, 3))} - 0.625rem);
    }
  }

  @supports (display: grid) {
    @include breakpoint("sm") {
      grid-template-columns: 1fr 1fr 1fr [right-edge];
      grid-column-gap: 1rem;
      grid-row-gap: 1.5rem;

      li {
        margin: 0;
        width: 100%;
      }
    }
  }
}

.list {
  margin-bottom: 1rem;

  li {
    margin-bottom: 1rem;

    @include breakpoint("sm") {
      margin-bottom: 1.5rem;
    }
  }
}

.shoppingItemLink {
  color: $black;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #5246F5 !important;
    .watchCta {
      transform: rotateX(90deg);
    }
  }

  &.buzzfeed {
    &:hover,
    &:focus {
      color: $blue;
    }

    .stacked {
      .imageWrap {
        border-radius: 4px;
        @include breakpoint("sm") {
          border-radius: 8px;
        }
      }
    }

    .inline {
      .imageWrap {
        border-radius: 4px;
      }
    }
  }
  &.buzzfeed_news {
    @extend %news-link-style;
  }
}

.imageWrap {
  background: $gray-1;
  overflow: hidden;
  position: relative;
  flex: 0 0 auto;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  &.big, &.wide {
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 0;
    }
  }

  &.big:before {
    padding-bottom: percentage(math.div(415, 625)); // dblbig image size from CDN
  }

  &.wide:before {
    padding-bottom: percentage(math.div(220, 625)); // dblwide image size from CDN
  }
}

.text {
  @include font-size('text-6');
  display: block;
  font-weight: $bold;

  @include breakpoint("md") {
    @include font-size('text-5');
  }
}

.stacked {
  .imageWrap {
    margin-bottom: 0.5rem;
  }
}

.inline {
  display: flex;

  .imageWrap {
    margin-right: 0.5rem;
    width: 115px;

    &.big {
      height: 75px;
    }

    &.wide {
      height: 40px;
    }

    @include breakpoint("md") {
      margin-right: 1rem;
    }
  }
}

.index {
  display: block;
  padding: 4px 10px;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  color: #fff;
  background: $pink;
  border-radius: 0 8px 0 0;
  min-width: 2rem;
}

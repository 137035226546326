@import "../../styles/lib/helpers";

$promoBackground: #F0FA81;
$hotTopicTextColor: #eb5369;
$promoLinkColor: $color-feed-purple;

.hotTopic {
  background-color: $promoBackground;
  border-radius: 67px;
  color: $hotTopicTextColor;
  font-family: Calistoga;
  font-weight: 400;
  font-size: 1rem;
  height: 31px;
  padding: 6px 12px;
  text-transform: uppercase;
  max-width: fit-content;
  text-wrap: nowrap;
  margin: 0 1rem 0.5rem 0;

  @include breakpoint('md') {
    margin-bottom: 0;
  }
}

.carret {
  width: 1rem;
}

.visualPromo {
  display: flex;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  flex-direction: column;

  @include breakpoint('sm') {
    &.noImage {
      height: initial;
    }
    height: 175px;
    flex-direction: row;
  }

  .visualPromoImage {
    position: relative;
    aspect-ratio: 39 / 31;
    height: 100%;
    @include breakpoint('sm') {
    }
  }

  &:hover .visualPromoCta {
    text-decoration: underline;
  }
}

.visualPromoIcon {
  height: 1.15rem;
}

.visualPromoContent {
  background: linear-gradient(to bottom, var(--promoThemeColor1), var(--promoThemeColor2));
  padding: 1rem;
  color: var(--promoThemeTextColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  svg {
    margin-bottom: 2px;
  }
  @include breakpoint('sm') {
    border-radius: 0px;
  }
}

.visualPromoPill {
  display: flex;
  align-items: center;

  @include breakpoint('sm') {
    font-size: 1rem;
  }
  margin-bottom: 0.5rem;
  svg {
    fill: var(--promoThemeTextColor);
  }
}

.visualPromoTitle {
  margin-bottom: 1.25rem;
  font-family: Calistoga;
  font-size: 1.25rem;
  @include breakpoint('sm') {
    margin-bottom: 0;
  }
}
.visualPromo.noImage .visualPromoTitle {
  @include breakpoint('sm') {
    margin-bottom: 1.25rem;
  }
}
.longVisualPromoTitle .visualPromoTitle {
  font-size: 1.125rem;
}

.visualPromoCta {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.commentsPromo {
  width: 100%;
  max-width: 600px;
  background: linear-gradient(to bottom, #DCE954, #F0FA81);
  border-radius: 8px;
  display: flex;
  text-decoration: none;
  margin-bottom: 2rem;

  picture img {
    border-radius: 8px 0 0 8px;
    aspect-ratio: 1 / 1;
    width: 130px;
    object-fit: cover;
    vertical-align: top;
    height: 100%;
    @include breakpoint('sm') {
      aspect-ratio: 5.5 / 1;
      width: 180px;
    }
  }

  &.noImage picture img {
    aspect-ratio: initial;
  }

  &:hover .commentsPromoCta {
    text-decoration: underline;
  }
  &.noImage .commentsPromoCta {
    margin-top: 0.75rem;
  }
}

.commentsPromoContent {
  padding: 0.875rem 1.5rem 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include breakpoint('sm') {
    padding: 1.25rem 1.5rem 1.25rem 1rem;
  }
}

.commentsPromoCopy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #222;
  text-decoration: none;
}
.commentsPromoCta,
.commentsPromoDescription {
  font-size: 1rem;
}
.commentsPromoTitle {
  font-family: Calistoga;
  font-weight: 400;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
}
.commentsPromoCta {
  color: $promoLinkColor;
  display: flex;
  svg {
    width: 10px;
    margin-left: 0.25rem;
  }
}

.textPromo {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-bottom: 2rem;
  align-items: left;

  @include breakpoint("md") {
    flex-direction: row;
    align-items: center;
  }
}

.textPromoTitle {
  font-size: 18px;
  font-weight: 700;
  flex-grow: 1;
}

.topicLink {
  align-items: center;
  color: $promoLinkColor;
  display: inline-flex;
  text-decoration: none;

  &:visited {
    color: $promoLinkColor;
  }

  &:hover {
    text-decoration: underline;
  }
}

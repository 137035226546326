@import "../../../../styles/lib/helpers";
$transition-duration: 300ms;

.container {
  position: -webkit-sticky;
  position: sticky;
  padding-top: $space-2;
  opacity: 1;
  transition: transform $transition-duration, top $transition-duration, opacity $transition-duration;
  transform: translateY(0);

  &.transitioning {
    opacity: 0;
    transform: translateY(-$space-4);
  }
}

:export {
  transitionDuration: $transition-duration
}

@import "../../styles/lib/helpers";

.sectionTitle {
  font-weight: $bold;
  margin-bottom: 1rem;

  &.buzzfeed {
    letter-spacing: -0.8px;
    font-family: 'Calistoga';
    font-size: 24px;
    font-weight: 400;
  }

  &.buzzfeed_news {
    @include font-size('text-6');
    color: $news-c-red;
    text-transform: uppercase;
    letter-spacing: .03125rem;
  }
}

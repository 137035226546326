@import "../../../../../styles/lib/helpers";

.featureImageWrapper {
  position: relative;
  .featureImagePlaceholder {
    position: relative;
    margin: -16px -16px 1rem;
    &:not(:empty) {
      // we set a forced padding on the element on the server
      // when the client content loads, we remove that and let the client handle things
      padding-bottom: 0 !important;
    }
  }
  &.featureImage-buzzfeed {
    .featureImagePlaceholder {
      @include breakpoint("sm") {
        margin: 0;
      }
    }
  }
  &.featureImage-buzzfeed_news {
    overflow: hidden;
    margin: -16px -16px 1rem;
    .featureImagePlaceholder {
      margin: 0;
    }
    @include breakpoint("sm") {
      margin-bottom: 0.5rem;
    }
  }
}

.featureImageShadow {
  &:after {
    content: "";
    background: linear-gradient(to top, rgba(0,0,0,0.65) 0%, transparent 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: none;

    @include breakpoint("sm") {
      display: block;
    }
  }
}

.featureText {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;

  @include breakpoint("sm") {
    display: block;
  }
}

.featureTextInner {
  color: #fff;
  bottom: 0;
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: 600px;
  text-align: left;
  z-index: 2;
  transform: translate3d(-50%,0,0);
  padding-bottom: 2rem;

  .title {
    @include font-size('text-1');
    line-height: 1.05;
    font-weight: 700;
    margin-bottom: 0.625rem;
  }

  .description {
    @include font-size('text-5');
  }
}

.featureImage {
  height: 0;
  position: relative;
  z-index: 3;

  video,
  img,
  .video,
  .image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.caption {
  margin: 0 $space-2;
  color: $white;
  font-size: $text-7;
  font-style: italic;
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  width: 100%;
  z-index: 5;

  @include breakpoint("sm") {
    margin: 0;
    top: 12px;
    right: 12px;
    bottom: auto;
    left: auto;
    background: rgba(0,0,0,0.5);
    padding: 6px;
    width: auto;
  }
}



@import "../../../../styles/lib/helpers";

.badge {
  display: inline-block;
  margin-right: $space-1;
  img, svg, picture {
    display: block;
    height: 30px;
    width: 30px;
    @include breakpoint("sm") {
      height: 40px;
      width: 40px;
    }
  }
}

.badgeList {
  display: flex;
}

.trendingBadgeContainer {
  display: inline-flex;
  align-items: center;
}

.trendingText {
  display: inline-flex;
  flex-direction: column;
}

.trendingTitle {
  font-size: $text-7;
  @include breakpoint("sm") {
    font-size: $text-6;
  }
}

.trendingViewCount {
  color: $red-5;
}

.trendingLink {
  display: inline-block;
}

@import "../../styles/lib/helpers";
@import "../../styles/lib/variables";

$animation-duration: 500;

.reactions {
  margin-bottom: 2rem;

  @media (min-width: $mobile-width) {
    padding: 24px;
  }
}

.reactionsList {
  align-items: flex-end;
  justify-content: space-between;
  list-style: none;
  width: 100%;
  position: relative;
  display: inline-flex;
  min-height: calc($space-4 + $space-05);
  padding: 10px 0;

  &.restrictedReactions {
    max-width: 400px;
  }

  @media (max-width: $mobile-width) {
    border: 2px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.12);
    border-radius: 64px;
    padding: 1rem 1.5rem;
  }

  @media (max-width: 400px) {
    padding: 1rem;
  }

  @media (max-width: 300px) {
    padding: 1rem 0;
    border: none;
    box-shadow: none;
  }
}

.hasReactions {
  @media (max-width: $mobile-width) {
    padding-bottom: 2rem;
  }
}

.reactionsItem {
  display: flex;
  max-width: 56px;
  @media (min-width: 640px) {
    border: 2px solid rgba(0, 0, 0, 0.12);
    border-radius: 64px;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.12);
  }

  button {
    background: none;
    border-radius: 64px;
    border: none;
    cursor: pointer;
    font-size: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0;
    @media (min-width: 640px) {
      padding: 11px;
    }
  }
}

.reactionsItemActive {
  cursor: pointer;
  &:hover {
    @media (min-width: 640px) {
      border: 2px solid #5246F5;
    }
  }
}

.count {
  position: absolute;
  text-align: center;
  width: 30px;
  cursor: default;
  color: #757575;
  font-size: 1rem;
  font-family: 'Proxima Nova';
  bottom: 5px;

  @media (min-width: 640px) {
    bottom: -1rem;
    margin-left: 10px;
  }
}

.error {
  color: #ee3322;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.confetti {
  position: relative;
  left: -16px;
  top: -25px;
  z-index: 2147483647; // max heighest number possible in css
  pointer-events: none;
  width: 0;
}

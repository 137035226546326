@import 'bf-solid/_lib/solid-helpers';

$shopping-peach: #ffe3d4;

.packageWrapper {
  border: 2px solid #000000;
  padding: $space-2;
  position: relative;
  margin: $space-6 $space-2 $space-3 0;
  background: #fff;

  @include solid-breakpoint('md') {
    margin-right: 18px;
  }
}

.heading {
  position: absolute;
  display: flex;
  justify-content: center;
  background: $fill-white;
  padding: 0 $space-1;
  top: -5px;
  left: 5%;
  transform: translateY(-50%);
  font-weight: $bold;
  font-size: 26px;

  svg {
    width: 150px;
  }

  @include solid-breakpoint('md') {
    font-size: 32px;

    svg {
      width: 200px;
    }
  }
}

.packageWrapper:before {
  content: '';
  position: absolute;
  right: -18px;
  top: -2px;
  width: 0;
  height: calc(100% + 5px);
  border-top: 15px solid transparent;
  border-left: 16px solid $shopping-peach;

  @include solid-breakpoint('md') {
    right: -20px;
    height: calc(100% + 7px);
    border-left: 18px solid $shopping-peach;
  }
}

.packageWrapper:after {
  content: '';
  position: absolute;
  bottom: -18px;
  left: -2px;
  right: 0;
  width: calc(100% + 5px);
  border-left: 15px solid transparent;
  border-top: 16px solid $shopping-peach;

  @include solid-breakpoint('md') {
    width: calc(100% + 7px);
    bottom: -20px;
    border-top: 18px solid $shopping-peach;
  }
}
@import "../../../../styles/lib/helpers";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: min-content;
  padding: 50px 18px 20px;
  gap: 12px;
  box-shadow: 0 -5px 10px rgba(0,0,0,0.3);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background-color: #fff;
  color: black;
  text-decoration: none;
  text-align: center;
  line-height: 1.2;
  transition: background-color .1s ease 0s;

  .icon {
    position: absolute;
    top: -25px;
    width: 64px;
    height: 64px;
    background-color: #fff;
    background-size: 100% 100%;
    border: 1px solid #ddd;
    border-radius: 16px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    padding: 6px;

    svg {
      fill: #e32;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 800;
  }

  .desc {
    padding: 0 24px;
    font-size: 15px;
  }

  .button {
    display: flex;
    padding: 12px 24px;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    background-color: $blue;
    border-radius: 24px;

    &:hover,
    &:focus {
      background-color: darken($blue, 20%);
    }

    &:active {
      background-color: darken($blue, 35%);
    }
  }

  .close {
    font-size: 15px;
    color: $blue;
  }

}
.mobileAppPromo {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  user-select: none;
  transition: all 00.25s ease;

  &.promoButton {
    bottom: 60px;
  }
  &.promoBanner {
    bottom: 0;
  }
}



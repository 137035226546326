@import "../../styles/lib/helpers";

.quizPartyPromo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: $white;
  background-image: url(./quiz-party-promo-bkgd.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  border-radius: 8px;
  border: 1px solid $gray-3;

  padding: $space-2;
  margin-bottom: $space-3;

  -webkit-appearance: none;
  font-family: $proxima;
  font-size: 1rem;

  @include breakpoint("sm") {
    font-size: $text-5;
    padding: $space-3 130px;
  }

  .logo {
    background-image: url('./quiz-party-logo.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 181px;
    height: 42px;
    margin: $space-1 0;

    @include breakpoint("sm") {
      width: 263px;
      height: 61px;
      margin: $space-2 0;
    }
  }

  .triviaLogo {
    background-image: url('./quiz-party-logo-trivia.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 207px;
    height: 53px;
    margin: $space-1 0;

    @include breakpoint("sm") {
      width: 273px;
      height: 70px;
      margin: $space-2 0;
    }
  }

  .title {
    @include screen-reader-only;
  }

  p {
    padding: 0 1.75rem;
    font-weight: $bold;
    margin-bottom: $space-2;
  }

  .triviaDesc {
    font-size: 0.9rem;
    @include breakpoint("sm") {
      font-size: 1rem;
    }
  }

  .quizPartyLink {
    display: block;
    width: 100%;
    padding: .5rem .875rem;

    font-size: 1rem;
    line-height: 1.5rem;
    text-decoration: none;
    text-align: center;

    color: #fff;
    background-color: $blue;
    border: 1px solid transparent;
    border-radius: $border-radius;
    transition: background-color .1s ease 0s;

    &:hover,
    &:focus {
      background-color: darken($blue, 20%);
    }

    &:active {
      background-color: darken($blue, 35%);
    }
  }
}
